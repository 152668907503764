<template>
  <b-row v-if="content.title">
    <b-col cols="12" md="9" class="pb-4">
      <h1>{{ content.title }}</h1>
      <h3 class="h6 text-muted my-3">{{ content.summary }}<br /><small class="text-info">By {{ content.author.fullName }} |  {{ $moment(content.date_start).format('MM/DD/YYYY') }}</small></h3>
      
      <b-img v-if="content.options.featuredImage" :src="content.options.featuredImage.src" fluid-grow class="mb-4" alt="Responsive image"></b-img>
      <div v-html="content.content" class="bg-light clear-both"></div>
      <div style="clear:both" class="float-right"><b-btn @click="$router.push('/website-local/happening-now/form/' + content.id)" variant="warning">Edit</b-btn></div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      content: {}
    }
  },
  computed: {
    ...mapGetters(['business'])
  },
  methods: {
    ...mapActions([
      'getContent',
      'resetContent'
    ])
  },
  created () {
    this.getContent({
      slug: this.$route.params.slug,
      business: this.business.id,
      type: 'happening_post'
    }).then(resp => {
      this.content = this.$store.getters.content[0]
    })
  },
  destroyed () {
    this.resetContent()
  }
}
</script>
